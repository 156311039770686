import Header from "../../Components/Header";
import "./style.css";
import arr from "./arr.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import HeaderDesc from "../../Components/HeaderDesc";
export default function Vebinar() {
  const Navigate = useNavigate();
  if (document.body.clientWidth < 640) {
    return (
      <div className="vebinar">
        <Header></Header>
        <div className="vebinarBody">
          <div className="classic">хочешь</div>
          <p>
            научиться правильно расшифровывать карты, хочешь
            <b> понять мир и себя</b> иди к нам
          </p>
          <img src={arr} alt="" />
          <video controls>
            <source src="./video/7d.mp4" type="video/mp4" />
          </video>
          <div
            id="scr"
            className="tas"
            onClick={() => {
              Navigate(routes.registration);
            }}
          >
            Регистрация
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="vebinar">
        {document.body.clientWidth < 640 ? (
          <Header></Header>
        ) : (
          <HeaderDesc></HeaderDesc>
        )}
        <div className="vebinarBody">
          <div className="classic">хочешь</div>
          <p>
            научиться правильно расшифровывать карты, хочешь
            <b> понять мир и себя</b> иди к нам
          </p>
          <img src={arr} alt="" />
          <h4>БЕСПЛАТНЫЙ 7 дневный курс</h4>
          <video controls>
            {" "}
            <source src="./video/7d.mp4" type="video/mp4" />
          </video>
          <div
            id="scr"
            className="tas"
            onClick={() => {
              Navigate(routes.registration);
            }}
          >
            Зарегистрироваться
          </div>
        </div>
      </div>
    );
  }
}
