export const routes = {
  cardChBefore: "/cardChooseBefore",
  cardChoose: "/cardChoose",
  vebinar: "/vebinar",
  registration: "/registration",
  main: "/",
  dop: "/dop",
  dopEm: "/dopEm",
  profile: "/profile",
  profileProgress: "/profileProgress",
  profileData: "/profileData",
  referal: "/referal",
  partner: "/partner",
  sendReview: "/sendReview",
  presents: "/presents",
  auth: "/auth",
  courses: "/courses",
  coursesEm: "/coursesEm",
  rewpage: "/rewpage",
};
