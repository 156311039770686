import { Store } from "react-notifications-component";
import "./style.css";
import { login } from "../../Api";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Header from "../../Components/Header";
import HeaderDesc from "../../Components/HeaderDesc";
export default function Auth() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const Navigate = useNavigate();
  return (
    <div className="registration">
      {document.body.clientWidth < 640 ? (
        <Header></Header>
      ) : (
        <HeaderDesc></HeaderDesc>
      )}
      <div className="classic">Вход</div>

      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Пароль"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div
        id="scr"
        className="tas"
        style={
          email.length > 0 && password.length > 0
            ? {}
            : { pointerEvents: "none", opacity: "0.8" }
        }
        onClick={() => {
          if (password.length < 6) {
            Store.addNotification({
              title: "Ошибка",
              message: "Пароль должен быть не менее 6-ти символов",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__bounceInDown"],
              animationOut: ["animate__animated", "animate__backOutUp"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            login(email, password).then((data) => {
              if (data != "error") {
                localStorage.user = JSON.stringify(data[0]);
                Navigate(routes.main);
              } else {
                Store.addNotification({
                  title: "Ошибка",
                  message: "Неверные данные для входа",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__bounceInDown"],
                  animationOut: ["animate__animated", "animate__backOutUp"],
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
              }
            });
          }
        }}
      >
        Войти
      </div>
      <div className="opbutt" onClick={() => Navigate(routes.registration)}>
        Регистрация
      </div>
    </div>
  );
}
