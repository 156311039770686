import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import { routes } from "../../routes";
import "./style.css";
import YouTube from "react-youtube";
export default function StarterPage() {
  const Navigate = useNavigate();
  return (
    <div className="starter">
      <Header></Header>
      <h3>
        добро <br />
        пожаловать!
      </h3>
      <YouTube videoId={"qD2Q2AJnWTY"} />
      <div
        id="scr"
        className="tas"
        onClick={() => {
          localStorage.first = "1";
          window.location.reload();
        }}
      >
        Далее
      </div>
    </div>
  );
}
