import { useLocation, useNavigate } from "react-router-dom";
import logo from "./logo.png";
import "./style.css";
import { routes } from "../../routes";
export default function HeaderDesc() {
  const location = useLocation();
  const Navigate = useNavigate();
  return (
    <div className="headerDesc">
      <img src={logo} alt="" onClick={() => Navigate(routes.main)} />
      <p>
        {location.pathname == routes.main && "Главное МЕНЮ"}
        {location.pathname == routes.presents && "Мои подарки"}
        {location.pathname == routes.referal && "Реферальная программа"}
        {location.pathname == routes.courses && "Обучающие курсы"}
        {location.pathname == routes.rewpage && "Отзывы"}
      </p>
      <svg
        width="87"
        className="lan"
        height="30"
        viewBox="0 0 87 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.0556641"
          width="86.6667"
          height="29.8889"
          rx="5.55556"
          fill="#6855D9"
        />
        <path
          d="M7.99648 21.5V8.57071H12.845C13.8382 8.57071 14.6716 8.74327 15.345 9.08838C16.0226 9.4335 16.5339 9.91751 16.8791 10.5404C17.2284 11.1591 17.403 11.8809 17.403 12.7058C17.403 13.5349 17.2263 14.2546 16.8727 14.8649C16.5234 15.471 16.0078 15.9402 15.326 16.2727C14.6442 16.601 13.8067 16.7652 12.8134 16.7652H9.36011V14.8207H12.4977C13.0785 14.8207 13.5541 14.7407 13.9245 14.5808C14.2949 14.4167 14.5684 14.1789 14.7452 13.8674C14.9262 13.5518 15.0167 13.1646 15.0167 12.7058C15.0167 12.2471 14.9262 11.8556 14.7452 11.5316C14.5642 11.2033 14.2886 10.955 13.9182 10.7866C13.5478 10.6141 13.0701 10.5278 12.4851 10.5278H10.3386V21.5H7.99648ZM14.6758 15.6414L17.8765 21.5H15.2629L12.119 15.6414H14.6758ZM26.9981 8.57071H29.3403V17.0177C29.3403 17.9436 29.1214 18.758 28.6837 19.4609C28.2502 20.1637 27.64 20.713 26.8529 21.1086C26.0659 21.5 25.1463 21.6957 24.0941 21.6957C23.0377 21.6957 22.116 21.5 21.3289 21.1086C20.5419 20.713 19.9316 20.1637 19.4981 19.4609C19.0646 18.758 18.8479 17.9436 18.8479 17.0177V8.57071H21.19V16.822C21.19 17.3607 21.3079 17.8405 21.5436 18.2614C21.7835 18.6822 22.1202 19.0126 22.5537 19.2525C22.9872 19.4882 23.5006 19.6061 24.0941 19.6061C24.6875 19.6061 25.201 19.4882 25.6345 19.2525C26.0722 19.0126 26.4089 18.6822 26.6446 18.2614C26.8803 17.8405 26.9981 17.3607 26.9981 16.822V8.57071Z"
          fill="#FFEE54"
        />
        <path
          d="M37.334 0.0556641H81.1118C84.18 0.0556641 86.6673 2.54297 86.6673 5.61122V24.389C86.6673 27.4573 84.18 29.9446 81.1118 29.9446H37.334V0.0556641Z"
          fill="#3E3292"
        />
        <path
          d="M45.2884 21.5V8.57071H53.6975V10.5341H47.6306V14.0442H53.2619V16.0076H47.6306V19.5366H53.748V21.5H45.2884ZM66.0009 8.57071V21.5H63.9176L57.8254 12.6932H57.7181V21.5H55.3759V8.57071H57.4719L63.5578 17.3838H63.6714V8.57071H66.0009ZM76.5035 12.6995C76.3983 12.3586 76.2531 12.0535 76.0679 11.7841C75.887 11.5105 75.6681 11.2769 75.4114 11.0833C75.1588 10.8897 74.8684 10.7445 74.5402 10.6477C74.2119 10.5467 73.8541 10.4962 73.4669 10.4962C72.7725 10.4962 72.1538 10.6709 71.6109 11.0202C71.0679 11.3695 70.6407 11.883 70.3293 12.5606C70.0221 13.234 69.8684 14.0547 69.8684 15.0227C69.8684 15.9992 70.0221 16.8262 70.3293 17.5038C70.6365 18.1814 71.0637 18.697 71.6109 19.0505C72.158 19.3998 72.7935 19.5745 73.5174 19.5745C74.174 19.5745 74.7422 19.4482 75.222 19.1957C75.706 18.9432 76.0784 18.5854 76.3394 18.1225C76.6003 17.6553 76.7308 17.1082 76.7308 16.4811L77.2611 16.5631H73.751V14.7323H78.9972V16.2854C78.9972 17.3923 78.7615 18.3497 78.2902 19.1578C77.8188 19.9659 77.1706 20.5888 76.3457 21.0265C75.5208 21.46 74.5738 21.6768 73.5048 21.6768C72.3137 21.6768 71.2678 21.4095 70.3672 20.875C69.4707 20.3363 68.7699 19.5724 68.2649 18.5833C67.7641 17.5901 67.5136 16.4116 67.5136 15.048C67.5136 14.0042 67.6609 13.072 67.9556 12.2513C68.2544 11.4306 68.671 10.734 69.2056 10.1616C69.7401 9.58502 70.3672 9.14731 71.0869 8.84849C71.8066 8.54545 72.5894 8.39394 73.4354 8.39394C74.1508 8.39394 74.8179 8.49916 75.4366 8.7096C76.0553 8.91583 76.6045 9.21044 77.0843 9.59344C77.5683 9.97643 77.9661 10.431 78.2775 10.9571C78.589 11.4832 78.7931 12.064 78.8899 12.6995H76.5035Z"
          fill="#8E83D1"
        />
      </svg>

      <article>Есть вопросы? Жми сюда</article>
      <svg
        width="31"
        height="16"
        className="ars"
        viewBox="0 0 31 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.166 7.13398C14.8327 7.51888 14.8327 8.48113 14.166 8.86603L5.91602 13.6292C5.24935 14.0141 4.41602 13.5329 4.41602 12.7631L4.41602 3.23686C4.41602 2.46706 5.24935 1.98593 5.91602 2.37083L14.166 7.13398Z"
          fill="#706FA9"
        />
        <path
          d="M29.166 7.13398C29.8327 7.51888 29.8327 8.48113 29.166 8.86603L20.916 13.6292C20.2493 14.0141 19.416 13.5329 19.416 12.7631L19.416 3.23686C19.416 2.46706 20.2493 1.98593 20.916 2.37083L29.166 7.13398Z"
          fill="#FFDB83"
        />
      </svg>

      <div className="writeButton">
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.7516 6.52649C12.7265 9.5257 10.5347 12.0546 7.53988 12.5152C6.35857 12.697 5.21517 12.5447 4.12162 12.0638C3.74716 11.8989 3.37952 11.8719 2.991 12.0073C2.46957 12.1891 1.94132 12.3519 1.41861 12.5308C1.13532 12.6274 0.87844 12.597 0.66416 12.3891C0.444767 12.176 0.408983 11.9183 0.50952 11.631C0.688868 11.1189 0.85288 10.6009 1.03436 10.0897C1.17281 9.70075 1.14938 9.33165 0.980682 8.95496C-0.417042 5.82837 1.0727 2.17575 4.26689 0.884959C7.77547 -0.532808 11.7262 1.49113 12.5842 5.14796C12.6311 5.34791 12.6686 5.55081 12.692 5.75455C12.7214 6.01102 12.7325 6.26918 12.7512 6.52649H12.7516ZM6.59543 5.83933C6.21373 5.8368 5.91425 6.12534 5.90956 6.50034C5.90445 6.88294 6.20436 7.18244 6.59117 7.18159C6.97117 7.18075 7.26809 6.888 7.26894 6.51257C7.2698 6.1363 6.97543 5.84144 6.59543 5.83933ZM3.86772 7.18202C4.24473 7.17991 4.54592 6.87999 4.54464 6.50836C4.54336 6.13715 4.2409 5.83976 3.86346 5.83891C3.48005 5.83849 3.1695 6.14643 3.17674 6.52059C3.18398 6.89222 3.48943 7.18455 3.86772 7.18202ZM9.32399 7.18202C9.70058 7.17906 10.0039 6.87746 10.0022 6.50751C10.0005 6.1304 9.68908 5.83301 9.30312 5.83891C8.92525 5.84482 8.63216 6.14432 8.63642 6.52059C8.64068 6.89222 8.94485 7.18455 9.32442 7.18202H9.32399Z"
            fill="#755C02"
          />
        </svg>
        Написать
      </div>
    </div>
  );
}
