import Profile from "..";
import Header from "../../../Components/Header";
import HeaderDesc from "../../../Components/HeaderDesc";
import "./style.css";
import pod from "./pod.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import bl from "./bl.png";
export default function Progress() {
  const Navigate = useNavigate();
  if (document.body.clientWidth > 640) {
    return (
      <div>
        <HeaderDesc></HeaderDesc>
        <div className="presntsDescBody">
          <Profile></Profile>
          <div className="progressBody">
            <div className="leftProgress">
              <p>ваш Прогресс</p>
              <h3>400</h3>
              <span>баллов</span>
              <img src={pod} alt="" />
              <div className="tas">Обменять баллы</div>
              <div className="opbutt" onClick={() => Navigate(routes.main)}>
                На главную
              </div>
            </div>
            <div className="rightPr">
              <img src={bl} alt="" />
              <p>
                За прохождение каждого урока, за выполнение заданий дня, мы
                начисляем баллы. Поскольку мы говорим про судьбу, то это так
                называемые судьбоносные баллы. Вы зарабатываете эти баллы и
                потом идёте в пункт обмена жизненных кризисов на счастье. К
                каждому количеству баллов соответствует определенный видео урок.
                Он тоже идет вам в подарок при условии, что вы просматриваете
                все уроки тест-драйва. Так же, вы можете вступить в партнерскую
                программу.Возможно, вы пожелаете порекомендовать прохождение
                тест – драйва вашим знакомым, друзьям и близким, за это тоже
                начисляются баллы! Внимательно смотрите программу дня для того,
                чтобы понять, какие задания на день у вас есть и какие баллы
                начисляются.Все видео, которые вы сможете получить как обмен
                ваших бонусных баллов, дадут вам более ясную и глубокую картину
                по разным жизненным ситуациям. Подборка этих видео сделана также
                не случайно, поскольку в этих видео дается углубление о том, как
                мы уже на практике работаем с данным инструментом – таро «грани
                Бога»
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className="">
        <Header></Header>
      </div>
    );
}
