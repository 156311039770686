import YouTube from "react-youtube";
import Header from "../../Components/Header";
import "./style.css";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { Zoom } from "react-reveal";
export default function MaterilaEm() {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = JSON.parse(urlParams.get("key"));
  const Navigate = useNavigate();
  return (
    <div className="matsEm">
      <Header></Header>

      <h3>{myParam.Title}</h3>
      <Zoom>
        <div className="linkVideo">
          <YouTube
            videoId={myParam.Link.replace(
              "https://www.youtube.com/watch?v=",
              ""
            )}
          />
        </div>
      </Zoom>
      <div className="matDesc">{myParam.Descr}</div>
      <div
        id="scr"
        className="tas"
        onClick={() => {
          Navigate(routes.registration);
        }}
      >
        Получить
      </div>
    </div>
  );
}
