import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import HeaderDesc from "../../Components/HeaderDesc";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";
// import required modules
import { Keyboard, Pagination, Navigation } from "swiper/modules";
import YouTube from "react-youtube";
export default function RewPage() {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    fetch("https://app.opora.digital/taro/getReview.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setReviews(data);
      });
  }, []);

  return document.body.clientWidth < 640 ? (
    <div className="curses">
      <Header></Header>
      <h3>Отзывы</h3>
      <div className="revbd">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Keyboard, Pagination, Navigation]}
          className="mySwiper"
        >
          {reviews &&
            reviews.map((em, index) => {
              let url = new URL(em.Link);
              let idV = url.searchParams.get("v");
              return (
                <SwiperSlide>
                  <YouTube id={idV}></YouTube>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  ) : (
    <div>
      <HeaderDesc></HeaderDesc>
      <div className="revbd">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Keyboard, Pagination, Navigation]}
          className="mySwiper"
        >
          {reviews &&
            reviews.map((em, index) => {
              let url = new URL(em.Link);
              let idV = url.searchParams.get("v");
              return (
                <SwiperSlide>
                  <YouTube id={idV}></YouTube>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
}
