export async function getDop() {
  const response = await fetch("https://app.opora.digital/taro/getDop.php");
  return response.json();
}
export async function getCourses() {
  const response = await fetch("https://app.opora.digital/taro/getCourses.php");
  return response.json();
}
export async function registration(name, phone, email, password) {
  let formData = new FormData();
  formData.append("name", name);
  formData.append("phone", phone);
  formData.append("email", email);
  formData.append("password", password);
  const response = await fetch(
    "https://app.opora.digital/taro/registration.php",
    {
      body: formData,
      method: "POST",
    }
  );
  return response.json();
}

export async function login(email, password) {
  let formData = new FormData();

  formData.append("email", email);
  formData.append("password", password);
  const response = await fetch("https://app.opora.digital/taro/auth.php", {
    body: formData,
    method: "POST",
  });
  return response.json();
}
