import Header from "../../Components/Header";
import "./style.css";
import i1 from "./img/arcane.png";
import i2 from "./img/2.png";
import i3 from "./img/3.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { Fade } from "react-reveal";
export default function CardChooseBefore() {
  const Navigate = useNavigate();
  return (
    <div className="CardChooseBefore">
      <Header></Header>
      <div className="chooseBeforeBody">
        <h2>
          Выберите <br />
          <span> карту</span>
        </h2>
        <Fade left>
          <img src={i1} alt="" onClick={() => Navigate(routes.cardChoose)} />
        </Fade>
        <Fade right>
          <img src={i2} alt="" />
        </Fade>
        <Fade left>
          <img src={i3} alt="" />
        </Fade>
      </div>
    </div>
  );
}
