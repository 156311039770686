import "./style.css";
import ph from "./card.png";
import { useEffect, useState } from "react";
export default function Card({
  lft,
  options,
  tpt,
  setCurrentCards,
  currentCards,
  cards,
  isDesc,
}) {
  const [open, isOpen] = useState(false);
  const [sr, setSr] = useState(ph);

  useEffect(() => {
    open &&
      setTimeout(() => {
        setSr(options.img);
      }, 250);
  }, [open]);
  useEffect(() => {
    isOpen(false);
    setSr(ph);
  }, [cards]);
  return (
    <div
      className={`card ${open && "rot"}`}
      style={{
        left: !isDesc ? `-${lft * 10}vw` : `-${lft * 4}vw`,
        bottom: tpt == "bottom" ? "20vw" : 0,
        top: open ? "-2vw" : "0",
      }}
      onClick={() => {
        isOpen(true);
        setCurrentCards((currentCards) => [...currentCards, options]);
        setTimeout(() => {
          document
            .getElementById("scr")
            .scrollIntoView({ behavior: "smooth", arg: false });
        }, 200);
      }}
    >
      <img src={sr} alt="" />
    </div>
  );
}
