import "./style.css";
import logo from "./img/logo.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
export default function Header() {
  const Navigate = useNavigate();
  return (
    <div className="header">
      <img src={logo} alt="" onClick={() => Navigate(routes.main)} />
      <div className="writeButton">
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.7516 6.52649C12.7265 9.5257 10.5347 12.0546 7.53988 12.5152C6.35857 12.697 5.21517 12.5447 4.12162 12.0638C3.74716 11.8989 3.37952 11.8719 2.991 12.0073C2.46957 12.1891 1.94132 12.3519 1.41861 12.5308C1.13532 12.6274 0.87844 12.597 0.66416 12.3891C0.444767 12.176 0.408983 11.9183 0.50952 11.631C0.688868 11.1189 0.85288 10.6009 1.03436 10.0897C1.17281 9.70075 1.14938 9.33165 0.980682 8.95496C-0.417042 5.82837 1.0727 2.17575 4.26689 0.884959C7.77547 -0.532808 11.7262 1.49113 12.5842 5.14796C12.6311 5.34791 12.6686 5.55081 12.692 5.75455C12.7214 6.01102 12.7325 6.26918 12.7512 6.52649H12.7516ZM6.59543 5.83933C6.21373 5.8368 5.91425 6.12534 5.90956 6.50034C5.90445 6.88294 6.20436 7.18244 6.59117 7.18159C6.97117 7.18075 7.26809 6.888 7.26894 6.51257C7.2698 6.1363 6.97543 5.84144 6.59543 5.83933ZM3.86772 7.18202C4.24473 7.17991 4.54592 6.87999 4.54464 6.50836C4.54336 6.13715 4.2409 5.83976 3.86346 5.83891C3.48005 5.83849 3.1695 6.14643 3.17674 6.52059C3.18398 6.89222 3.48943 7.18455 3.86772 7.18202ZM9.32399 7.18202C9.70058 7.17906 10.0039 6.87746 10.0022 6.50751C10.0005 6.1304 9.68908 5.83301 9.30312 5.83891C8.92525 5.84482 8.63216 6.14432 8.63642 6.52059C8.64068 6.89222 8.94485 7.18455 9.32442 7.18202H9.32399Z"
            fill="#755C02"
          />
        </svg>
        Написать
      </div>
    </div>
  );
}
