import { Route, Routes } from "react-router-dom";
import "./App.css";
import { routes } from "./routes";
import CardChooseBefore from "./Pages/CardChooseBefore";
import CardChoose from "./Pages/CardChoose";
import Vebinar from "./Pages/Vebinar";
import Registration from "./Pages/Registration";
import Main from "./Pages/Main";
import DopMaterials from "./Pages/DopMaterials";
import MaterilaEm from "./Pages/MaterialEm";
import Profile from "./Pages/Profile";
import ProfileData from "./Pages/Profile/Data";
import Progress from "./Pages/Profile/Progress";
import SendReview from "./Pages/Profile/SendReview";
import "animate.css";
import "react-notifications-component/dist/theme.css";
import { ReactNotifications } from "react-notifications-component";
import StarterPage from "./Pages/StarterPage";
import Auth from "./Pages/Auth";
import Curses from "./Pages/Curses";
import CurseEm from "./Pages/Curses/CurseEm";
import Referal from "./Pages/Profile/Referal";
import Presents from "./Pages/Profile/Presents";
import Partner from "./Pages/Profile/Partner";
import RewPage from "./Pages/RewPage";
function App() {
  return (
    <div className="App">
      <ReactNotifications />
      <Routes>
        <Route
          path={routes.cardChBefore}
          element={<CardChooseBefore></CardChooseBefore>}
        ></Route>
        <Route
          path={routes.cardChoose}
          element={<CardChoose></CardChoose>}
        ></Route>
        <Route path={routes.vebinar} element={<Vebinar />}></Route>
        <Route path={routes.registration} element={<Registration />}></Route>
        <Route
          path={"/"}
          element={localStorage.first ? <Main /> : <StarterPage></StarterPage>}
        ></Route>
        <Route path={routes.dop} element={<DopMaterials />}></Route>
        <Route path={routes.dopEm} element={<MaterilaEm />}></Route>
        <Route path={routes.profile} element={<Profile />}></Route>
        <Route path={routes.profileData} element={<ProfileData />}></Route>
        <Route path={routes.profileProgress} element={<Progress />}></Route>
        <Route path={routes.presents} element={<Presents />}></Route>
        <Route path={routes.partner} element={<Partner />}></Route>
        <Route path={routes.referal} element={<Referal />}></Route>
        <Route path={routes.sendReview} element={<SendReview />}></Route>
        <Route path={routes.main} element={<Main />}></Route>
        <Route path={routes.auth} element={<Auth />}></Route>
        <Route path={routes.courses} element={<Curses />}></Route>
        <Route path={routes.coursesEm} element={<CurseEm />}></Route>
        <Route path={routes.rewpage} element={<RewPage />}></Route>
      </Routes>
    </div>
  );
}

export default App;
