import YouTube from "react-youtube";
import Header from "../../../Components/Header";
import "./style.css";
import { Fade } from "react-reveal";
import HeaderDesc from "../../../Components/HeaderDesc";
import Profile from "../index";
import pod from "./pod.png";
export default function Presents() {
  return document.body.clientWidth < 640 ? (
    <div className="presents">
      <Header></Header>
      <h3>Мои подарки</h3>
      <Fade left>
        <div className="prItem">
          <YouTube videoId="wXPlxV4fO9A" />
          <p>Подарок</p>
        </div>
      </Fade>
      <Fade right>
        <div className="prItem">
          <YouTube videoId="wXPlxV4fO9A" />
          <p>Подарок</p>
        </div>
      </Fade>
      <Fade left>
        <div className="prItem">
          <YouTube videoId="wXPlxV4fO9A" />
          <p>Подарок</p>
        </div>
      </Fade>
    </div>
  ) : (
    <div>
      <HeaderDesc></HeaderDesc>
      <div className="presntsDescBody">
        <Profile></Profile>
        <div className="presentsBodyRight">
          {[1, 1, 1, 1, 1, 1, 1, 1, 1].map((em, index) => {
            return (
              <div>
                <img src={pod} alt="" />
                <p>Название подарка</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
