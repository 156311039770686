import { useState } from "react";
import Header from "../../Components/Header";
import "./style.css";
import { login, registration } from "../../Api";
import { Store } from "react-notifications-component";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { Zoom } from "react-reveal";
import HeaderDesc from "../../Components/HeaderDesc";
export default function Registration() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const Navigate = useNavigate();
  return (
    <div className="registration">
      {document.body.clientWidth < 640 ? (
        <Header></Header>
      ) : (
        <HeaderDesc></HeaderDesc>
      )}
      <Zoom left>
        <div className="classic">Регистрация</div>
      </Zoom>
      <input
        type="text"
        value={name}
        placeholder="Ваше имя*"
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="tel"
        value={phone}
        placeholder="Телефон*"
        onChange={(e) => setPhone(e.target.value)}
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Пароль"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div
        id="scr"
        className="tas"
        style={
          name.length > 0 &&
          phone.length > 0 &&
          email.length > 0 &&
          password.length > 0
            ? {}
            : { pointerEvents: "none", opacity: "0.8" }
        }
        onClick={() => {
          if (password.length < 6) {
            Store.addNotification({
              title: "Ошибка",
              message: "Пароль должен быть не менее 6-ти символов",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__bounceInDown"],
              animationOut: ["animate__animated", "animate__backOutUp"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            registration(name, phone, email, password).then((data) => {
              if (data == "Ok") {
                login(email, password).then((data) => {
                  localStorage.user = JSON.stringify(data[0]);
                  Store.addNotification({
                    title: "Поздравляем",
                    message: "Регистрация прошла успешно",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__bounceInDown"],
                    animationOut: ["animate__animated", "animate__backOutUp"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true,
                    },
                  });
                  Navigate(routes.main);
                });
              } else {
                Store.addNotification({
                  title: "Ошибка",
                  message: "Пользователь с таким email уже зарегистрирован",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__bounceInDown"],
                  animationOut: ["animate__animated", "animate__backOutUp"],
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
              }
            });
          }
        }}
      >
        Подать заявку
      </div>
      <div className="opbutt" onClick={() => Navigate(routes.auth)}>
        Авторизация
      </div>
    </div>
  );
}
