import Profile from "..";
import Header from "../../../Components/Header";
import HeaderDesc from "../../../Components/HeaderDesc";
import "./style.css";
export default function Partner() {
  return document.body.clientWidth < 640 ? (
    <div className="partnersh">
      <Header></Header>

      <div className="classic">Регистрация в партнерской программе</div>
      <form
        id="ltForm503702"
        class="lt-normal-form lt-form-inner lt-form"
        action="https://theta-ok.ru/pl/lite/block-public/process-html?id=2057800835"
        method="post"
        data-open-new-window="0"
      >
        <input type="hidden" name="formParams[setted_offer_id]" />
        <br />
        <input
          type="text"
          maxlength="60"
          placeholder="Введите ваше имя"
          name="formParams[full_name]"
        />
        <br />
        <input
          type="text"
          maxlength="60"
          placeholder="Введите ваш эл. адрес"
          name="formParams[email]"
        />
        <br />
        <input
          type="text"
          maxlength="60"
          placeholder="Введите ваш телефон"
          name="formParams[phone]"
        />
        <br />
        <input name="formParams[userCustomFields][1136667]" type="hidden" />
        <br />
        <button
          className="tas"
          type="submit"
          id="button3304041"
          onclick="if(window['btnprs66966be0de7ae']){return false;}window['btnprs66966be0de7ae']=true;setTimeout(function(){window['btnprs66966be0de7ae']=false},6000);return true;"
        >
          УЧАСТВУЮ
        </button>
        <br />
        <input name="formParams[dealCustomFields][982074]" type="hidden" />
        <br />
        <input name="formParams[dealCustomFields][982075]" type="hidden" />
        <br />
        <input name="formParams[dealCustomFields][982076]" type="hidden" />
        <br />
        <input name="formParams[dealCustomFields][982077]" type="hidden" />
        <br />
        <input name="formParams[dealCustomFields][982078]" type="hidden" />
        <br />
        <input name="formParams[dealCustomFields][1757259]" type="hidden" />
        <br />
        <input
          type="hidden"
          id="122830366966be09b8cf"
          name="__gc__internal__form__helper"
          class="__gc__internal__form__helper"
          value=""
        />
        <input
          type="hidden"
          id="122830366966be09b8cfref"
          name="__gc__internal__form__helper_ref"
          class="__gc__internal__form__helper_ref"
          value=""
        />
        <input type="hidden" name="requestTime" value="1721134048" />
        <input
          type="hidden"
          name="requestSimpleSign"
          value="78444b03989f79428596eb527fd548d1"
        />
        <input type="hidden" name="isHtmlWidget" value="1" />
      </form>
      <span id="gccounterImgContainer"></span>
    </div>
  ) : (
    <div>
      <HeaderDesc></HeaderDesc>
      <div className="presntsDescBody">
        <Profile></Profile>
        <div className="regPP">
          {" "}
          <div className="classic">Регистрация</div>
          <form
            id="ltForm503702"
            class="lt-normal-form lt-form-inner lt-form"
            action="https://theta-ok.ru/pl/lite/block-public/process-html?id=2057800835"
            method="post"
            data-open-new-window="0"
          >
            <input type="hidden" name="formParams[setted_offer_id]" />
            <br />
            <input
              type="text"
              maxlength="60"
              placeholder="Введите ваше имя"
              name="formParams[full_name]"
            />
            <br />
            <input
              type="text"
              maxlength="60"
              placeholder="Введите ваш эл. адрес"
              name="formParams[email]"
            />
            <br />
            <input
              type="text"
              maxlength="60"
              placeholder="Введите ваш телефон"
              name="formParams[phone]"
            />
            <br />
            <input name="formParams[userCustomFields][1136667]" type="hidden" />
            <br />
            <button
              className="tas"
              type="submit"
              id="button3304041"
              onclick="if(window['btnprs66966be0de7ae']){return false;}window['btnprs66966be0de7ae']=true;setTimeout(function(){window['btnprs66966be0de7ae']=false},6000);return true;"
            >
              УЧАСТВУЮ
            </button>
            <br />
            <input name="formParams[dealCustomFields][982074]" type="hidden" />
            <br />
            <input name="formParams[dealCustomFields][982075]" type="hidden" />
            <br />
            <input name="formParams[dealCustomFields][982076]" type="hidden" />
            <br />
            <input name="formParams[dealCustomFields][982077]" type="hidden" />
            <br />
            <input name="formParams[dealCustomFields][982078]" type="hidden" />
            <br />
            <input name="formParams[dealCustomFields][1757259]" type="hidden" />
            <br />
            <input
              type="hidden"
              id="122830366966be09b8cf"
              name="__gc__internal__form__helper"
              class="__gc__internal__form__helper"
              value=""
            />
            <input
              type="hidden"
              id="122830366966be09b8cfref"
              name="__gc__internal__form__helper_ref"
              class="__gc__internal__form__helper_ref"
              value=""
            />
            <input type="hidden" name="requestTime" value="1721134048" />
            <input
              type="hidden"
              name="requestSimpleSign"
              value="78444b03989f79428596eb527fd548d1"
            />
            <input type="hidden" name="isHtmlWidget" value="1" />
          </form>
          <span id="gccounterImgContainer"></span>
        </div>
      </div>
    </div>
  );
}
